import { Component } from '@angular/core';
import { App } from '@capacitor/app';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Market } from '@ionic-native/market/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { NavigationRoutes } from './GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from './GeneralUtilis/Navigation/NavigationElement';
import { ShoopingCartUtils } from './GeneralUtilis/ShoopingCar.utils';
import { StorageService } from './GeneralUtilis/Storage';
import { AssociatedInstance } from './businessCore/AssociatedInstance';
import { CustomerData } from './businessCore/CustomerData';
import { Direction } from './businessCore/Direction';
import { GI_Component } from './businessCore/GI_Component';
import { RegisterData } from './businessCore/RegisterData';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SearchableSelectComponent } from './components/searchable-select/searchable-select.component';
import { TutorialVideoComponent } from './components/tutorial-video/tutorial-video.component';
import { CameraService } from './provider/Services/CameraService';
import { LocationService } from './provider/Services/LocationService';
import { webServiceProvider } from './provider/webServiceProvider';
import { ManageUserKeysProvider } from './providers/manage-user-keys/manage-user-keys';
import { PushNotificationsProvider } from './providers/push-notifications/push-notifications';
import {
  GlobalEvents,
  GlobalEventsService,
} from './utils/globalEvents.service';
import { register } from 'swiper/element/bundle';

register();

export interface Page {
  id: number;
  title: string;
  component: NavigationRoutes;
  show: boolean;
  icon: string;
  expanded: boolean;
  childs?: Page[];
  closeSession?: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss', 'app.component2.scss'],
})
export class AppComponent {
  //@ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  // Pagina incial que utiliza la aplicacion
  //rootPage: any = FamiliesComponent;
  rootPage: string = 'FamiliesComponent';

  // Cojunto de paginas que se encuentran dentro de la aplicacion
  pages: Page[];

  // Variables para mantener el nombre e identificador del usuario
  // que se encuentra activo en la aplicacion
  userName: string = '';
  userId: string = '';

  // Variable para saber si ya existe una sesion de inicio activa
  loginStatus: boolean = false;

  // showedAlert es una variable para controlar cuando mostrar una alerta
  // confirmAlert es la variable sobre la que se crea el objeto de alerta
  // que se debe mostrar y ocultar segun se requiera
  showedAlert: boolean;
  confirmAlert: any;

  // Returns the version of the app, e.g.: “1.2.3”
  appVersionNumber: string = '1.0.0';

  AppId: number = 1; // Mercasa VIP
  // AppId: number = 2; // FMCM (Homex Express)

  componentsByUser: GI_Component[] = [];
  associatedPeople: AssociatedInstance[] = [];
  associatedPerson: AssociatedInstance = new AssociatedInstance(
    '',
    'Default',
    0,
    [],
    '',
    ''
  );
  dispositiveId: string = '';

  /**
   * Constructor de la clase que recibe los siguientes injections
   * @param platform The Platform service can be used to get information about your current device
   * @param events Events is a publish-subscribe style event system for sending and responding
   * to application-level events across your app.
   * @param storage Storage is an easy way to store key/value pairs and JSON objects.
   * @param alert An Alert is a dialog that presents users with information
   * or collects information from the user using inputs.
   */
  constructor(
    public platform: Platform,
    public storage: StorageService,
    public alert: AlertController,
    public socialShare: SocialSharing,
    public appVersion: AppVersion,
    public apiProvider: webServiceProvider,
    public market: Market,
    public popoverCtrl: PopoverController,
    public device: Device,
    public modalCtrl: ModalController,
    public pushService: PushNotificationsProvider,
    public manageKeysProvider: ManageUserKeysProvider,
    private events: GlobalEventsService,
    private navService: NavigationService,
    private LocationService: LocationService,
    private CameraService: CameraService,
    private shoopingCartUtils: ShoopingCartUtils,
    private navCtrl: NavController
  ) {
    this.storage.create();
    this.initializeApp();

    // Definicion de todas las paginas con que cuenta el menu lateral de la aplicacion
    // Se le asocian otros atributos para controlar el nombre que se despliega, si es necesario ocultar
    // la opcion, el icono que tiene asociado y si tiene sub opciones o no.
    this.pages = [
      {
        id: 3,
        title: 'Iniciar Sesión',
        component: NavigationRoutes.LoginPage,
        show: true,
        icon: 'fas fa-sign-in-alt',
        expanded: false,
      },
      {
        id: 5,
        title: 'Inicio',
        component: NavigationRoutes.FamiliesComponent,
        show: true,
        icon: 'fas fa-home',
        expanded: false,
      },
      {
        id: 9,
        title: 'Mi Perfil',
        component: null,
        show: true,
        icon: 'far fa-user',
        expanded: false,
        childs: [
          {
            id: 0,
            title: 'Mi Cuenta',
            component: NavigationRoutes.MyProfileGeneralInfoComponent,
            show: true,
            icon: 'fas fa-info',
            expanded: false,
          },
          {
            id: 1,
            title: 'Credenciales',
            component: NavigationRoutes.MyProfileUpdatepassComponent,
            show: true,
            icon: 'fas fa-user-shield',
            expanded: false,
          },
          {
            id: 2,
            title: 'Direcciones',
            component: NavigationRoutes.MyProfileDirectionsComponent,
            show: true,
            icon: 'fas fa-map-marker-alt',
            expanded: false,
          },
          {
            id: 3,
            title: 'Mis Solicitudes',
            component: NavigationRoutes.MyProfileCasemanagementComponent,
            show: true,
            icon: 'fas fa-envelope',
            expanded: false,
          },
          {
            id: 4,
            title: 'Contacto',
            component: NavigationRoutes.ContactComponent,
            show: true,
            icon: 'fas fa-address-card',
            expanded: false,
          },
          // { id: 4, title: 'Restricciones', component: MyProfileRestrictionsComponent, show: true, icon: 'far fa-clock', expanded: false, hasChild: false }
        ],
      },
      {
        id: 0,
        title: 'Productos ',
        component: NavigationRoutes.HomePage,
        show: true,
        icon: 'fas fa-grip-horizontal',
        expanded: false,
      },
      // { id: 11, title: 'Aplicar Promos', component: MyPromoListComponent, show: false, icon: 'fas fa-gift', expanded: false,  childs: [] },
      {
        id: 6,
        title: 'Pedidos',
        component: null,
        show: true,
        icon: 'fas fa-list',
        expanded: false,
        childs: [
          {
            id: 0,
            title: 'Confirmar Pedidos',
            component: NavigationRoutes.OrderConfirmationComponent,
            show: true,
            icon: 'fas fa-clipboard-check',
            expanded: false,
          },
          {
            id: 3,
            title: 'Seguimiento de Pedidos',
            component: NavigationRoutes.OrderTraceComponent,
            show: true,
            icon: 'fas fa-clock',
            expanded: false,
          },
          {
            id: 2,
            title: 'Mis Pedidos',
            component: NavigationRoutes.OrderHistoryComponent,
            show: true,
            icon: 'fas fa-clipboard-list',
            expanded: false,
          },
          {
            id: 1,
            title: 'Mis Pagos',
            component: NavigationRoutes.MyPaymentComponent,
            show: true,
            icon: 'fas fa-credit-card',
            expanded: false,
          },
        ],
      },
      {
        id: 7,
        title: 'Mis Listas',
        component: NavigationRoutes.MyCustomListComponent,
        show: true,
        icon: 'far fa-list-alt',
        expanded: false,
      },
      {
        id: 12,
        title: 'Devoluciones',
        component: NavigationRoutes.OrderRetHistoryComponent,
        show: true,
        icon: 'fas fa-clipboard-list',
        expanded: false,
      },
      {
        id: 2,
        title: 'Registro',
        component: NavigationRoutes.ValidateClientComponent,
        show: true,
        icon: 'fas fa-user-plus',
        expanded: false,
      },
      {
        id: 10,
        title: 'Datos de Interés',
        component: null,
        show: true,
        icon: 'fas fa-folder-open',
        expanded: false,
        childs: [
          {
            id: 1,
            title: 'Acuerdo de Responsabilidad de credenciales',
            component: NavigationRoutes.UseTermsComponent,
            show: true,
            icon: 'fas fa-file-signature',
            expanded: false,
          },
          {
            id: 0,
            title: 'Términos y Condiciones',
            component: NavigationRoutes.TermsComponent,
            show: true,
            icon: 'far fa-clipboard',
            expanded: false,
          },
          {
            id: 2,
            title: 'Tutoriales de Uso',
            component: NavigationRoutes.TutorialsComponent,
            show: true,
            icon: 'fas fa-film',
            expanded: false,
          },
        ],
      },
      {
        // Desde aquí debe ser show:false
        id: 20,
        title: 'Activos',
        component: null,
        show: false,
        icon: 'fas fa-list',
        expanded: false,
        childs: [
          {
            id: 22,
            title: 'Solicitud de traslado',
            component: NavigationRoutes.AssetsTransferComponent,
            show: false,
            icon: 'fas fa-clipboard-list',
            expanded: false,
          },
          {
            id: 24,
            title: 'Aprobación de traslado',
            component: NavigationRoutes.AssetsApprovalComponent,
            show: false,
            icon: 'fas fa-clipboard-check',
            expanded: false,
          },
          {
            id: 25,
            title: 'Recepción de activo',
            component: NavigationRoutes.AssetAcceptanceComponent,
            show: false,
            icon: 'fas fa-clipboard-check',
            expanded: false,
          },
          {
            id: 29,
            title: 'Certificación de activos fijos',
            component: NavigationRoutes.AssetCertificationPage,
            show: false,
            icon: 'fas fa-clipboard-list',
            expanded: false,
          },
          {
            id: 30,
            title: 'Aprobador certificación AF',
            component: NavigationRoutes.AssetCertificationApprovalPage,
            show: false,
            icon: 'fas fa-clipboard-check',
            expanded: false,
          },
          {
            id: 31,
            title: 'Auditoría activos fijos',
            component: NavigationRoutes.AssetAuditPage,
            show: false,
            icon: 'fas fa-glasses',
            expanded: false,
          },
        ],
      },
      {
        id: 90,
        title: 'Ordenes de Compra',
        component: null,
        show: false,
        icon: 'fas fa-money-check',
        expanded: false,
        childs: [
          {
            id: 14,
            title: 'Crear orden de compra',
            component: NavigationRoutes.PurchOrderRecordPage,
            show: false,
            icon: 'fas fa-money-check',
            expanded: false,
          },
          {
            id: 15,
            title: 'Aprobar orden de compra',
            component: NavigationRoutes.PurchOrderToBeApprovedPage,
            show: false,
            icon: 'fas fa-clipboard-check',
            expanded: false,
          },
          {
            id: 16,
            title: 'Confirmación de orden de compra',
            component: NavigationRoutes.PurchOrderToBeConfirmedPage,
            show: false,
            icon: 'fas fa-clipboard-list',
            expanded: false,
          },
          {
            id: 40,
            title: 'Remitir O.C.',
            component: NavigationRoutes.RemissionListPage,
            show: false,
            icon: 'fas fa-clipboard-list',
            expanded: false,
          },
          {
            id: 41,
            title: 'Facturar O.C.',
            component: NavigationRoutes.InvoiceListPage,
            show: false,
            icon: 'fas fa-clipboard-list',
            expanded: false,
          },
          {
            id: 42,
            title: 'Asociar O.C.',
            component: NavigationRoutes.AssignEinvoicePage,
            show: false,
            icon: 'fas fa-clipboard-check',
            expanded: false,
          },
        ],
      },
      {
        id: 26,
        title: 'Check List',
        component: NavigationRoutes.ChecklistInstancePage,
        show: false,
        icon: 'fas fa-clipboard-check',
        expanded: false,
      },
      {
        id: 28,
        title: 'Registro de proveedores',
        component: NavigationRoutes.VendorRegisterComponent,
        show: false,
        icon: 'fas fa-globe',
        expanded: false,
      },
      {
        id: 27,
        title: 'Viajes',
        component: NavigationRoutes.TripRegisterPage,
        show: false,
        icon: 'fas fa-map-marked-alt',
        expanded: false,
      },
      {
        id: 4,
        title: 'Cerrar Sesión',
        component: null,
        show: true,
        icon: 'fas fa-sign-out-alt',
        expanded: false,
        closeSession: true,
      },
    ];

    // this.initializeApp();

    // evento utilizado para publicar el método 'update:pages' y que el mismo pueda ser accedido desde
    // cualquier parte de la aplicacion. Esta funcion lo que pretende es actualizar las opciones del menu
    // para desplegar las que se deben y ocultar las que no
    this.events.getGlobalEvent(GlobalEvents.UPDATE_PAGES).subscribe((data) => {
      const param: number = data.param;
      const args: GI_Component[] = data.args;

      this.displayPages(param, args);
    });

    // evento utilizado para publicar el método 'show:pages' y que el mismo pueda ser accedido desde
    // cualquier parte de la aplicacion. Esta funcion lo que pretende es dejar visibles las opciones
    // del menu para desplegar las que se deben ver
    this.events.getGlobalEvent(GlobalEvents.SHOW_PAGES).subscribe((data) => {
      const param: any = data.param;
      const args: GI_Component[] = data.args;

      this.showPages(param, args);
    });

    // evento utilizado para publicar el método 'setLoginPage' y que el mismo pueda ser accedido desde
    // cualquier parte de la aplicacion. Esta funcion lo que pretende es setear los datos del usuario que esta
    // ingresando en la aplicacion.
    this.events
      .getGlobalEvent(GlobalEvents.SET_LOGIN_DATA)
      .subscribe((data) => {
        const userName: string = data.userName;
        const userId: string = data.userId;
        const loginStatus: boolean = data.loginStatus;

        this.setLoginData(userName, userId, loginStatus);
        this.GetVersionCode();
      });

    this.events
      .getGlobalEvent(GlobalEvents.SET_COMPONENTS_BY_USER)
      .subscribe((data) => {
        const pComponentsByUser: GI_Component[] = data.pComponentsByUser;

        this.setComponentsByUser(pComponentsByUser);
      });

    this.events
      .getGlobalEvent(GlobalEvents.SET_ASSOCIATED_PEOPLE)
      .subscribe((data) => {
        const pAssociatedPeople: AssociatedInstance[] = data.pAssociatedPeople;

        this.setAssociatedPeople(pAssociatedPeople);
      });

    // this.getAppVersion();
    // this.openTutorialVideo();
    // this.GetVersionCode();
    this.initializeAppAux();

    // DEBUG
    //this.showAllPages();
    //DEBUG

    this.updateCart();
  }

  showAllPages() {
    this.pages.forEach((page) => {
      if (page.childs) {
        page.childs.forEach((child) => {
          child.show = true;
        });
      }
      page.show = true;
    });
  }

  async updateCart() {
    setInterval(async () => {
      NavBarComponent.shoppingCar =
        await this.shoopingCartUtils.reloadShoppingCar();
    }, 500);
  }

  initializeAppAux() {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.platform.ready().then(() => {

    // this.initializeApp();
    // Llamado al metodo que oculta y despliega las opciones del menú según el estado de la sesion
    this.pageSetup();

    this.getAppVersion();

    // this.openTutorialVideo();

    this.GetVersionCode();
    // });
  }

  /**
   * Metodo utilizado para abrir el video de introduccion a la App
   */
  async openTutorialVideo() {
    this.storage
      .get('showTutorial')
      .then(async (data) => {
        let showTutorial = data != null ? (data as boolean) : true;

        if (showTutorial) {
          let tutorialPopup = await this.popoverCtrl.create({
            cssClass: 'custom-popover',
            component: TutorialVideoComponent,
            componentProps: { showHeader: false },
            backdropDismiss: false,
          });

          tutorialPopup.present();

          tutorialPopup.onDidDismiss().then(() => {
            this.GetVersionCode();
          });
        } else {
          this.GetVersionCode();
        }
        this.storage.set('showTutorial', false);
      })
      .catch(() => {
        this.storage.set('showTutorial', false);
      });
  }

  /**
   * Metodo utilizado para desplegar u ocultar una determinada pagina
   * @param param id de la pagina que se encuentra registrada en el arreglo pages
   */
  displayPages(param: number, args: GI_Component[] = []) {
    if (args.length) {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          if (data.childs.length) {
            this.pages[index].show = !this.pages[index].show;
            this.pages[index].childs.forEach((subdata, subindex) => {
              if (
                args.some(
                  (giComponent) => giComponent.componentId == subdata.id
                )
              ) {
                this.pages[index].childs[subindex].show =
                  !this.pages[index].childs[subindex].show;
              }
            });
          }
        }
      });
    } else {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          this.pages[index].show = !this.pages[index].show;
        }
      });
    }
  }

  /**
   * Metodo utilizado para desplegar u ocultar una determinada pagina
   * @param param id de la pagina que se encuentra registrada en el arreglo pages
   */
  showPages(param: any, args: GI_Component[] = []) {
    // console.log(param, args);
    if (args.length) {
      this.pages.forEach((data, index) => {
        if (
          data.title.toLowerCase() == param.toLowerCase() &&
          data.childs.length
        ) {
          this.pages[index].childs.forEach((subdata, subindex) => {
            if (
              args.some(
                (giComponent) =>
                  giComponent.componentId == subdata.id && giComponent.type == 0
              )
            ) {
              this.pages[index].show = true;
              this.pages[index].childs[subindex].show = true;
            }
          });
        }
      });
    } else {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          this.pages[index].show = true;
        }
      });
    }
  }

  /**
   * Metodo utilizado para desplegar u ocultar una determinada pagina
   * @param param id de la pagina que se encuentra registrada en el arreglo pages
   */
  hidePages(param: number, args: GI_Component[] = []) {
    if (args.length) {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          if (data.childs.length) {
            this.pages[index].show = false;
            this.pages[index].childs.forEach((subdata, subindex) => {
              if (
                args.some(
                  (giComponent) => giComponent.componentId == subdata.id
                )
              ) {
                this.pages[index].childs[subindex].show = false;
              }
            });
          }
        }
      });
    } else {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          this.pages[index].show = false;
        }
      });
    }
  }

  /**
   * Oculta todos los componentes
   */
  hideAllPages() {
    this.pages.forEach((page) => {
      page.show = false;
    });
  }

  /**
   * Metodo para inicializar la aplicacion
   * y controla cuando el usuario desea salir de la aplicacion, mostrando una alerta
   * para preguntar si esta seguro de la accion.
   */
  initializeApp() {
    this.platform.ready().then(async () => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.

      if (this.platform.is('cordova')) {
        this.pushService.configuracionInicial().then(() => {
          this.storage.get('dispositiveId').then((resp) => {
            this.dispositiveId = resp;
          });

          this.storage.get('register_data').then((response) => {
            const register_data = JSON.parse(response) as RegisterData;
            let cedulaAct = register_data?.id_card;
            if (
              cedulaAct !== undefined &&
              cedulaAct !== null &&
              cedulaAct !== ''
            ) {
              let OS = this.device.platform + ' ' + this.device.version;
              this.manageKeysProvider.manageUserKey(
                cedulaAct,
                this.dispositiveId,
                '4b2937e8-f5c2-4821-bdba-c9893e002715',
                OS
              );
            }
          });
        });

        // Splash
        //await SplashScreen.hide();
      }

      // Confirm exit
      this.platform.backButton.subscribeWithPriority(-1, () => {
        /* TODOM
        if (!this.routerOutlet.canGoBack()) {
          if (!this.showedAlert) {
            this.confirmExitApp();
          } else {
            this.showedAlert = false;
            this.confirmAlert.dismiss();
          }
        }*/
      });

      // Ask for the GPS Permission
      await this.askGPSPermission();

      // Ask for the Camera Permission
      await this.askCameraPermission();
    });
  }

  async askGPSPermission() {
    let hasPermission: boolean =
      await this.LocationService.checkGPSPermission();
    if (!hasPermission) {
      await this.LocationService.requestGPSPermission();
    }
  }

  async askCameraPermission() {
    let hasPermission: boolean =
      await this.CameraService.checkCameraPermission();
    if (!hasPermission) {
      await this.CameraService.requestCameraPermission();
    }
  }

  /**
   * Metodo para confirmar que el usuario esta seguro de salir de la aplicacion
   * Se utiliza un objeto de tipo alert, el cual se despliega con el mensaje
   * message: "¿ Está seguro que desea salir de la aplicación ?"
   * y se muestran dos botones (Cancelar, Aceptar)
   */
  async confirmExitApp() {
    this.showedAlert = true;
    this.confirmAlert = await this.alert.create({
      header: 'Salir',
      message: '¿ Está seguro que desea salir de la aplicación ?',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            this.showedAlert = false;
          },
        },
        {
          text: 'Aceptar',
          handler: () => {
            App.exitApp();
          },
        },
      ],
    });
    this.confirmAlert.present();
  }

  /**
   * Metodo utilizado para registrar los datos del usuario que tiene la sesion activa
   * @param userName nombre del usuario con la sesion activa
   * @param userId identificador del usuario que tiene la sesion activa
   * @param loginStatus estado, para saber si se cuenta o no con una sesion activa
   */
  setLoginData(userName: string, userId: string, loginStatus: boolean) {
    this.userId = userId;
    this.userName = userName;
    this.loginStatus = loginStatus;
  }

  /**
   *
   * @param pComponentsByUser
   */
  setComponentsByUser(pComponentsByUser: GI_Component[]) {
    this.componentsByUser = pComponentsByUser;
  }

  /**
   *
   * @param pAssociatedPeople
   */
  setAssociatedPeople(pAssociatedPeople: AssociatedInstance[]) {
    this.associatedPeople = pAssociatedPeople;
  }

  /**
   * Metodo utilizado para configurar el despliegue de opciones o paginas que se muestran
   * en el menu lateral de la aplicacion. Esto se lleva acabo segun la informacion consultada
   * en el register_data, pues si no existe quiere decir que no existe ningun usuario con la
   * sesion activa y por ende debe proceder a ocultar ciertas opciones del menú.
   */
  pageSetup() {
    this.storage.get('componentsByUser').then((data) => {
      //Se verifica si tiene acceso a algún componente de acuerdo a sus roles.
      if (data) {
        this.componentsByUser = JSON.parse(data) as GI_Component[];
      }
    });
    this.storage.get('register_data').then((data) => {
      this.pages.forEach((element) => {
        //Nos aseguramos que todos los componentes se hallen ocultos.
        element.show = false;
      });

      //Mostramos los componentes que se pueden ver siempre (0, 5, 10).
      this.displayPages(5); //Inicio (Se muestra)
      this.displayPages(0); //Productos (Se muestra)
      this.displayPages(10); //Datos de interes (Se muestra)

      this.displayPages(40);
      //this.displayPages(28);

      if (data == null) {
        // NO existe una sesion activa.
        //Mostramos los componentes que pueden verse sin una sesión activa (2, 3)
        this.displayPages(3); //Iniciar sesión (Se muestra)
        this.displayPages(2); //Registro (Se muestra)

        this.setLoginData('¡Hola!', '', false); // Se registra un ¡Hola!, sin ID y con el estado de sesion activa en falso
      } else {
        // SI existe una sesion activa
        const register_data = JSON.parse(data) as RegisterData;
        //Mostramos los componentes para todo aquel que tenga una sesión activa (4, 6, 9, 7, 12).
        this.showPages(9); //Mi perfil (Se muestra)
        this.showPages(6); //Pedidos (Se muestra)
        this.showPages(7); //Mis listas (Se muestra)
        this.showPages(12); //Devoluciones (Se muestra)
        this.showPages(4); //Cerrar sesión (Se muestra)
        this.showPages(29); //Cerrar sesión (Se muestra)
        this.showPages(30); //Cerrar sesión (Se muestra)
        this.showPages(31); //Cerrar sesión (Se muestra)
        this.showPages(24); //Cerrar sesión (Se muestra)

        if (this.componentsByUser.length) {
          this.componentsByUser.forEach((value) => {
            if (value.type == 0) {
              this.showPages(value.componentId);
            } else if (value.type == 3 && value.ListAppsComponent.length) {
              this.showPages(value.componentName, value.ListAppsComponent);
            }
          });
        }

        // Se registran los datos(nombre, id, estado de la sesion) del usuario activo
        this.setLoginData(register_data.user_name, register_data.id_card, true);

        this.updateRegisterData();

        this.setUserInfo();
      }
    });

    this.storage.get('associatedPeople').then((data) => {
      //Se verifica si tiene acceso a algún componente de acuerdo a sus roles.
      if (data) {
        this.associatedPeople = JSON.parse(data) as AssociatedInstance[];
      }
    });
  }

  /**
   * Metodo utilizado para expandir/abrir una opcion del menu lateral
   * Si la opcion posee subopciones solamente se expande, pero si representa una
   * pagina, el programa redireccionara al component que tenga asociada la opcion
   * @param page page in the array
   * @param hasChild 0 = principal page, 1 = secondary page
   */
  openPage(page: Page, hasChild: boolean) {
    //console.log('PAGE', page, 'HasChild', hasChild);
    if (page.childs) {
      page.expanded = !page.expanded;
    } else {
      if (page.id == 3) {
        this.navService.navigateTo(page.component, {}, { replaceUrl: true });
      } else if (page.id == 4 && page.closeSession == true) {
        // Cerrar Sesión
        this.storage.set('register_data', null).then(() => {
          this.pageSetup();
          this.GetVersionCode();
          this.presentAlert(
            'Estado de la Sesión',
            'Su sesión ha sido cerrada con éxito!'
          );
        });
        this.pages.forEach((page) => {
          page.expanded = false;
        }); //Lo expandido es cerrado.
        this.componentsByUser.forEach((value) => {
          //Todos los componentes se ocultan.
          if (value.type == 0) {
            this.hidePages(value.componentId);
          } else if (value.type == 3 && value.ListAppsComponent.length) {
            this.hidePages(value.componentId, value.ListAppsComponent);
          }
        });
        this.associatedPeople = [];

        this.storage.clear();
      } else {
        this.navService.navigateTo(page.component, { auth: 0, code: page.id });
      }
    }
  }

  /**
   * Metodo asociado al boton de Facebook que se encuentra en el menu lateral
   * desplegando una nueva pestaña con el enlace de facebook de la compañia
   */
  openFacebook() {
    window.open('https://www.facebook.com/homexcr/', '_system', 'location=yes');
    return false;
  }

  /**
   * Metodo asociado al boton de Whatsapp que se encuentra en el menu lateral
   * desplegando una nueva pestaña con el enlace al chat de Mercasa
   */
  sendWhatsappMsg() {
    this.socialShare
      .shareViaWhatsAppToReceiver('+50670713080', '')
      .then(() => {})
      .catch(() => {});
    return false;
  }

  /**
   * Metodo asociado al boton de Mail que se encuentra en el menu lateral
   * desplegando una nueva pestaña para enviar un nuevo correo a la direccion
   * servicio_al_cliente@grupointeca.com de la empresa
   */
  openMail() {
    window.open('mailto:servicio_al_cliente@grupointeca.com');
    return false;
  }

  shareApp() {
    // AppId: number = 1; // Mercasa VIP
    // AppId: number = 2; // FMCM (Homex Express)
    let msg =
      this.AppId == 2
        ? 'Descargar la aplicación Homex Express, Disponible en Play Store: '
        : `Descargar la aplicación Mercasa VIP: 
         • Play Store: https://play.google.com/store/apps/details?id=senseIT.cs.MercasaVIP
         • App Store: https://apps.apple.com/cr/app/mercasa-vip/id1554351581`;

    this.socialShare.shareWithOptions({
      message: msg,
      subject: 'Atención!',
      url: '',
      chooserTitle: 'Compartir con una aplicación',
    });
    return false;
  }

  /**
   * Metodo utilizado para presentar una alerta con un determinado título y mensaje
   * redirijiendo a la pantalla principal de la aplicación
   * @param title string con el titulo que se muestra en la alerta
   * @param msg string con el contenido del mensaje que se desea alertar
   */
  async presentAlert(title: string, msg: string) {
    let alert = await this.alert.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.navService.navigateTo(
              NavigationRoutes.FamiliesComponent,
              {},
              { replaceUrl: true }
            );
          },
        },
      ],
      backdropDismiss: false,
    });
    alert.present();

    alert.onDidDismiss().then(() => {
      this.events.publish(GlobalEvents.UPDATE_PRODUCTS);
    });
  }

  getAppVersion() {
    // this.platform.ready().then(() => {
    if (this.platform.is('cordova')) {
      this.appVersion.getVersionNumber().then((data) => {
        this.appVersionNumber = data;
      });
    }
    // });
  }

  /**
   * @param customer_data
   * @param id_card
   */
  setRegisterData(customer_data: CustomerData, id_card: string) {
    const register_data = new RegisterData();

    register_data.customerId = customer_data.AccountNum;
    register_data.user_name = customer_data.CustomerName;

    register_data.OriginSite = customer_data.OriginSite;
    register_data.Hierarchy = customer_data.Hierarchy;
    register_data.DeliveryRoute = customer_data.DeliveryRoute;
    register_data.priceList = customer_data.PriceGroup;

    // Validate if customer has addresses
    if (customer_data.CustomerAddresses) {
      customer_data.CustomerAddresses.forEach((address) => {
        const direction = new Direction();

        direction.recId = address.RecId;
        direction.latitude = address.Latitude;
        direction.longitude = address.Longitude;
        direction.direction = address.Address;
        direction.addressName = address.AddressName;
        direction.deliveryDays = address.DeliveryDays;
        direction.priceGroupId = address.PriceGroupId;
        direction.principalDeliveryDay = address.PrincipalDeliveryDay;
        direction.secondDeliveryDay = address.SecondDeliveryDay;
        direction.city = address.City;

        register_data.directions.push(direction);
      });
    }

    register_data.id_card = id_card;
    this.storage.set('register_data', JSON.stringify(register_data));

    // Actualizar ultima vez en que los datos fueron actualizados
    this.storage.set('ultimaActualizacionCliente', new Date());

    this.setLoginData(register_data.user_name, register_data.id_card, true);
  }

  /**
   * Funcion utilizada para informacion del cliente siempre y cuadno exista una sesion iniciada
   * Verifica si la ultima vez que se actualizo fue hace menos de un día
   */
  updateRegisterData() {
    this.storage.get('ultimaActualizacionCliente').then((fecha) => {
      if (
        fecha == null ||
        new Date().getTime() - new Date(fecha).getTime() > 14400000
      ) {
        //14400000   => 4 hrs
        this.storage.get('register_data').then((data) => {
          if (data != null) {
            // SI existe una sesion activa
            let dataAux = JSON.parse(data) as RegisterData;
            this.apiProvider.getCustomer(dataAux.id_card).subscribe((res) => {
              // Validar informacion del cliente
              let data: any[] = res as any[];
              if (data && data.length > 0 && data[0]) {
                const customer_data = data[0] as CustomerData;

                this.setRegisterData(customer_data, dataAux.id_card);
              }
            });
          }
        });
      }
    });
  }

  /**
   * create procedure [dbo].[updateCustAccount](
   *   @userid varchar(50),
   *  @VersionName varchar(30),
   *  @OS_type varchar(10),
   *  @OS_Version varchar(10),
   *  @Phone_Version varchar(100)
   * )
   * device.cordova
   * device.model
   * device.platform
   * device.uuid
   * device.version
   * device.manufacturer
   * device.isVirtual
   * device.serial
   */
  setUserInfo() {
    try {
      // this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.appVersion.getVersionNumber().then((data) => {
          this.appVersionNumber = data;

          let userid = this.userId;
          let VersionName = this.appVersionNumber;
          let OS_type = this.device.platform;
          let OS_Version = this.device.version;
          let Phone_Version = this.device.manufacturer + this.device.model;

          this.apiProvider
            .updateCustAccountInfo(
              userid,
              VersionName,
              OS_type,
              OS_Version,
              Phone_Version
            )
            .subscribe(() => {});
        });
      }
      // });
    } catch (error) {}
  }

  /**
   *
   */
  GetVersionCode() {
    this.updateEnableVersion(true);

    let appVersion: string = '';
    // this.platform.ready().then(() => {
    if (this.platform.is('cordova')) {
      // You are on a device, cordova plugins are accessible

      this.appVersion
        .getVersionCode()
        .then((data) => {
          if (data) {
            appVersion = data.toString();

            this.apiProvider.getVersionControl(appVersion).subscribe((data) => {
              if (data) {
                switch (data) {
                  case 'NO_TIME': // VERSION NO REGISTRADA
                    this.updateEnableVersion(false);
                    this.presentVersionAlert(
                      'Esta versión de la aplicación no se encuentra registrada!',
                      false
                    );
                    break;
                  case 'BEFORE_TIME': //VERSION NO HA EMPEZADO A REGIR
                    this.updateEnableVersion(false);
                    this.presentVersionAlert(
                      'Esta versión de la aplicación no ha comenzado a regir!',
                      false
                    );
                    break;
                  case 'AFTER_TIME': //VERSION VENCIDA
                    this.updateEnableVersion(false);
                    this.presentVersionAlert(
                      'Actualmente existe una versión más actualizada de la aplicación! Si desea seguir realizando compras debe actualizar la aplicación!',
                      true
                    );
                    break;
                  case 'ON_TIME': //VERSION BUENA
                    this.updateEnableVersion(true);
                    break;
                  default:
                    this.updateEnableVersion(true);
                    break;
                }
              }
            });
          }
        })
        .catch((err) => {
          this.updateEnableVersion(true);
        });
    } else {
      // Cordova not accessible, add mock data if necessary
      this.updateEnableVersion(true);
    }
    // }).catch(() => {
    //   // Cordova not accessible, add mock data if necessary
    //   this.updateEnableVersion(true);
    // })
  }

  /**
   *
   */
  updateEnableVersion(versionState: boolean) {
    this.storage.set('enable_version', versionState).then(() => {});
  }

  /**
   *
   * @param subtitle
   * @param goToStore
   */
  async presentVersionAlert(subtitle: string, goToStore: boolean) {
    let alert = await this.alert.create({
      header: 'Versión de la aplicación',
      subHeader: subtitle,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {
            // Redirigir al exit
            this.confirmExitApp();
          },
        },
      ],
      backdropDismiss: false,
    });

    if (goToStore) {
      alert.buttons.push({
        text: 'Actualizar',
        handler: () => {
          if (goToStore) {
            // Redirigir a la tienda
            if (this.platform.is('ios')) {
              // AppId: number = 1; /
              // AppId: number = 2;
              if (this.AppId == 1) {
                // Mercasa VIP
                this.market.open('senseIT.cs.MercasaVIP');
              } else {
                // FMCM (Homex Express)
                this.market.open('senseIT.cs.FMCM');
              }
            } else if (this.platform.is('android')) {
              // AppId: number = 1; // Mercasa VIP
              // AppId: number = 2; // FMCM (Homex Express)
              if (this.AppId == 1) {
                this.market.open('senseIT.cs.MercasaVIP');
              } else {
                this.market.open('senseIT.cs.FMCM');
              }
            }
          }
        },
      });
    }
    alert.present();
  }

  /**
   *
   * Muestra un popover donde se elige al nuevo responsable de los activos.
   *
   */
  async selectAssociated() {
    let popover = await this.modalCtrl.create({
      component: SearchableSelectComponent,
      componentProps: { objectsList: this.associatedPeople, typeAction: 1 },
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      let data = res.data;
      if (data != null) {
        this.associatedPerson = data;
        this.associatedPerson.name = this.associatedPerson.name.toLowerCase();
        this.storage.set(
          'associatedPerson',
          JSON.stringify(this.associatedPerson)
        );
        if (this.associatedPerson.name.toLowerCase() == 'default') {
          this.navService.navigateTo(NavigationRoutes.FamiliesComponent);
          this.pageSetup();
        } else {
          this.AssociatedPageSetup();
        }
      }
    });
  }

  // /**
  //  *
  //  * Muestra un popover donde se elige al nuevo responsable de los activos.
  //  *
  //  */
  AssociatedPageSetup() {
    let startPage;
    this.hideAllPages();
    this.associatedPerson.roleHazId.forEach((roleHaz) => {
      if (roleHaz) {
        this.componentsByUser.some((componentGroup) => {
          if (componentGroup.ListAppsComponent.length) {
            componentGroup.ListAppsComponent.some((component) => {
              if (component.roleId.indexOf(roleHaz) == 0) {
                this.showPages(
                  componentGroup.componentName,
                  componentGroup.ListAppsComponent
                );
                if (!startPage) {
                  startPage = this.setStartPage(
                    componentGroup.componentId,
                    componentGroup.ListAppsComponent
                  );
                }
                return true;
              } else {
                return false;
              }
            });
          }
          if (componentGroup.roleId.indexOf(roleHaz) == 0) {
            this.showPages(componentGroup.componentId);
            if (!startPage) {
              startPage = this.setStartPage(
                componentGroup.componentId,
                componentGroup.ListAppsComponent
              );
            }
            return true;
          } else {
            return false;
          }
        });
      }
    });
    // console.log(startPage);
    this.pages[this.pages.length - 1].show = true;
    if (startPage) {
      this.navService.navigateTo(startPage.component);
    } else {
      this.navService.navigateTo(NavigationRoutes.LogoScreenComponent);
    }
  }

  /**
   * Metodo utilizado para desplegar u ocultar una determinada pagina
   * @param param id de la pagina que se encuentra registrada en el arreglo pages
   */
  setStartPage(param: number, args: GI_Component[] = []) {
    //console.log(param);
    let startPage;
    if (args.length) {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          if (data.childs.length) {
            this.pages[index].show = true;
            this.pages[index].childs.forEach((subdata, subindex) => {
              if (
                args.some(
                  (giComponent) => giComponent.componentId == subdata.id
                )
              ) {
                startPage = this.pages[index].childs[subindex];
              }
            });
          }
        }
      });
    } else {
      this.pages.forEach((data, index) => {
        if (data.id == param) {
          startPage = this.pages[index];
        }
      });
    }
    return startPage;
  }
}
