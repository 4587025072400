<app-nav-bar [title]="'Aprobador certificación AF'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button (click)="refreshPage()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box">
    <ion-searchbar
      type="text"
      placeholder="Buscar"
      (ionChange)="searchCert($event)"
    ></ion-searchbar>

    <div class="flex-container nowrap" style="margin: 5px; display: flex">
      <ion-button
        class="filter-btn"
        (click)="presentCertFilterPopover()"
        style="width: 100%"
      >
        Filtros
        <ion-icon name="options"></ion-icon>
      </ion-button>

      <ion-button
        class="sort-btn"
        (click)="presentCertSortAlert()"
        style="width: 100%"
      >
        Ordenar
        <ion-icon name="reorder-three-outline"></ion-icon>
      </ion-button>
    </div>

    <ion-item lines="none">
      <ion-checkbox (ionChange)="selectAll($event)" slot="start"></ion-checkbox>
      <ion-label>
        <h2>Seleccionar todo</h2>
      </ion-label>
    </ion-item>
    

    <div class="content">
      <ion-list>
        <ion-item *ngFor="let cert of certListToSearch">
          <ion-checkbox [(ngModel)]="cert.selected" slot="start"></ion-checkbox>
          <ion-label>
            <h2>{{ cert.getWorkerName() }}</h2>
            <p>Período: {{ cert.getCertificatedPeriod() }}</p>
            <p>Departamento: {{ cert.getWorkerDepartmentName() }}</p>
            <p>Cant. activos: {{ cert.getAssetQty() }}</p>
            <p>Premio: {{ cert.getRewardAmount() }} {{ cert.getCurrency() }}</p>
            <p>Fecha de certificación: {{ cert.getPetitionDate() | date: 'dd-MM-yyyy' }}</p>
          </ion-label>
          <ion-button (click)="openLink(cert.getDocumentLink())" fill="clear">
            <ion-icon slot="icon-only" name="ios-document-outline"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
    </div>

    <div style="display: flex; justify-content: flex-end; margin: 20px 0;">
      <ion-button
        (click)="showCertListPopOver(2)"
        color="danger"
        [disabled]="!certListSelected.length"
        style="margin-right: 10px"
      >
        Rechazar
      </ion-button>
      <ion-button
        (click)="showCertListPopOver(1)"
        [disabled]="!certListSelected.length"
      >
        Aprobar
      </ion-button>
    </div>
  </div>
</ion-content>
