<app-nav-bar [title]="'Mi Cuenta'" [start]="'GoBack'"></app-nav-bar>

<ion-content class="ion-padding">
  <!-- Spinner de carga -->
  <div class="ion-text-center" *ngIf="loadingInfo">
    <ion-spinner name="dots"></ion-spinner>
    <h6>Cargando Información</h6>
  </div>

  <!-- Mensaje de error -->
  <div class="ion-text-center" *ngIf="!loadingInfo && infoError">
    No se pudo consultar la información del cliente
  </div>

  <!-- Información de la cuenta -->
  <div *ngIf="!loadingInfo && !infoError" class="sectionContainer">
    <h4 class="ion-text-center title2">Información de la Cuenta</h4>
    <div class="flexBlockContainer">

      <!-- Nombre del cliente -->
      <div class="flexContainer">
        <div class="titleContent2">Nombre:</div>
        <div class="textContent">{{ customer_info.CustomerName }}</div>
      </div>

      <!-- Código del cliente -->
      <div class="flexContainer">
        <div class="titleContent2">Código del cliente:</div>
        <div class="textContent">{{ customer_info.AccountNum }}</div>
      </div>

      <!-- Identificación -->
      <div class="flexContainer">
        <div class="titleContent2">Identificación:</div>
        <div class="textContent">{{ register_data.id_card }}</div>
      </div>

      <!-- Teléfono -->
      <div class="flexContainer">
        <div class="titleContent2">Teléfono(s):</div>
        <div class="textContent" *ngIf="customer_info.Phone">
          {{ customer_info.Phone }}
        </div>
        <div class="textContent" *ngIf="!customer_info.Phone">
          Sin teléfono registrado
        </div>
        <ion-button
          class="button-blue"
          fill="clear"
          (click)="editPhoneInfo()"
          *ngIf="customer_info.CreditMax <= 0 && customer_info.Phone"
        >
          <ion-icon name="create"></ion-icon>
        </ion-button>
      </div>

      <!-- Correo electrónico -->
      <div class="flexContainer">
        <div class="titleContent2">Correo Electrónico:</div>
        <div class="textContent" *ngIf="customer_info.email">
          {{ customer_info.email }}
        </div>
        <div class="textContent" *ngIf="!customer_info.email">
          Sin correo registrado
        </div>
        <ion-button
          class="button-blue"
          fill="clear"
          (click)="editEmailInfo()"
          *ngIf="customer_info.CreditMax <= 0 && customer_info.email"
        >
          <ion-icon name="create"></ion-icon>
        </ion-button>
      </div>

    </div>
  </div>
</ion-content>
