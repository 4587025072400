<app-nav-bar [title]="'Validación de Cliente'"></app-nav-bar>

<ion-content class="ion-paddding">
  <div class="flexContainer">
    <div class="loginContainer">
      <form class="formContainer" #registerForm="ngForm">
        <ion-item>
          <ion-select
            label="Tipo de Cédula"
            label-placement="floating"
            class="floating"
            [(ngModel)]="c_type"
            [ngModelOptions]="{ standalone: true }"
            interface="popover"
          >
            <ion-select-option
              *ngFor="let ct of card_types"
              (ionSelect)="updateCardType(ct.value)"
              value="{{ ct.value }}"
              >{{ ct.name }}</ion-select-option
            >
          </ion-select>
        </ion-item>

        <ion-item>
          <a class="required"></a>
          <ion-input
            label-placement="floating"
            class="floating"
            type="text"
            *ngIf="c_type == -1"
            name="id_card"
            required
            [disabled]="c_type == -1"
          ></ion-input>
          <ion-input
            label="{{ card_types[c_type]?.format }}"
            label-placement="floating"
            class="floating"
            type="text"
            mask="*-****-****"
            maxlength="11"
            pattern=".{11,11}"
            *ngIf="card_types[c_type]?.value == 0"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>
          <ion-input
            label="{{ card_types[c_type]?.format }}"
            label-placement="floating"
            class="floating"
            type="text"
            mask="********************"
            pattern=".{1,20}"
            #username
            minlength="1"
            maxlength="20"
            *ngIf="card_types[c_type]?.value == 1"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>

          <ion-input
            label="{{ card_types[c_type]?.format }}"
            label-placement="floating"
            class="floating"
            type="text"
            mask="*-***-******"
            maxlength="12"
            pattern=".{12,12}"
            *ngIf="card_types[c_type]?.value == 2"
            name="id_card"
            [(ngModel)]="id_card"
            required
          ></ion-input>
          <ion-button
            type="button"
            fill="clear"
            (click)="deleteIdCard()"
            style="height: 40px; text-align: center; margin-right: 0px"
          >
            <ion-icon name="close" style="font-size: 20px"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-button
          (click)="validate()"
          expand="block"
          class="submit-btn"
          type="submit"
          [disabled]="!registerForm.form.valid || validationInProgress"
        >
          <ion-icon name="md-checkmark-circle-outline" class="mr-5"></ion-icon>
          Validar
        </ion-button>
      </form>
    </div>
  </div>
</ion-content>

