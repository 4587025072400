<app-nav-bar [title]="'Certificación de AF'" [start]="'GoBack'">
  <ion-buttons slot="end">
    <ion-button (click)="refreshPage()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content class="ion-paddding">
  <div *ngIf="isLoaded" class="box">
    <div class="content">
      <p
        style="
          color: red;
          margin-bottom: 4px;
          margin-left: 20px;
          text-align: left;
        "
      >
        Total de activos: {{ assetCertificationList.length }}
      </p>

      <div *ngFor="let key of getKeys(assetCertificationMap)">
        <app-general-divider
          [title]="
            key +
            ': ' +
            getAssetCertification(assetCertificationMap, key)[0].companyName
          "
          [objectList]="getAssetCertification(assetCertificationMap, key)"
        ></app-general-divider>
      </div>
    </div>
  </div>
</ion-content>

<div style="display: flex; justify-content: flex-end; align-items: center; padding: 5px;">
  
  <ion-button (click)="isInAvailableCertPeriod()">
    Certificar
  </ion-button>
</div>

