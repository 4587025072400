<app-nav-bar [title]="'Registro'" [start]="'GoBack'"></app-nav-bar>

<ion-content class="ion-padding" center text-center>
  <h3>Datos Generales</h3>
  <div class="formDiv">
    <form (ngSubmit)="registerNextStep()" #registerForm="ngForm">
      <ion-list>
        <ion-item>
          <a class="required"></a>

          <ion-input
            label="Tipo de Cédula"
            label-placement="floating"
            class="floating"
            type="text"
            name="c_name"
            [(ngModel)]="register_data.c_name"
            readonly
            required
          ></ion-input>
        </ion-item>

        <ion-item>
          <a class="required"></a>
          <ion-input
            label="Número de Cédula"
            label-placement="floating"
            class="floating"
            type="text"
            name="id_card"
            [(ngModel)]="register_data.id_card"
            readonly
            required
          ></ion-input>
        </ion-item>

        <ion-item *ngIf="register_data.c_type == 2">
          <a class="required"></a>
          <ion-input
            label="Organización"
            label-placement="floating"
            class="floating"
            type="text"
            name="user_name"
            [(ngModel)]="register_data.user_name"
            required
          ></ion-input>
        </ion-item>

        <ion-item *ngIf="register_data.c_type != 2">
          <a class="required"></a>
          <ion-input
            label="Nombre"
            label-placement="floating"
            class="floating"
            type="text"
            name="name"
            [(ngModel)]="register_data.name"
            required
          ></ion-input>
        </ion-item>

        <ion-item *ngIf="register_data.c_type != 2">
          <a class="required"></a>
          <ion-input
            label="Primer Apellido"
            label-placement="floating"
            class="floating"
            type="text"
            name="firstLastName"
            [(ngModel)]="register_data.firstLastName"
            required
          ></ion-input>
        </ion-item>

        <ion-item *ngIf="register_data.c_type != 2">
          <a class="required"></a>
          <ion-input
            label="Segundo Apellido"
            label-placement="floating"
            class="floating"
            type="text"
            name="secondLastName"
            [(ngModel)]="register_data.secondLastName"
            required
          ></ion-input>
        </ion-item>

        <ion-item>
          <ion-input
            label="Nombre Comercial"
            label-placement="floating"
            class="floating"
            type="text"
            name="commercial_name"
            [(ngModel)]="register_data.commercial_name"
          ></ion-input>
        </ion-item>

        <ion-item class="register_item">
          <a class="required"></a>
          <ion-input
            label="Contraseña"
            label-placement="floating"
            class="floating"
            [type]="isActive ? 'text' : 'password'"
            minlength="8"
            maxlength="15"
            name="password"
            [(ngModel)]="register_data.password"
            required
          ></ion-input>
          <ion-button
            type="button"
            (click)="isActive = !isActive"
            isActive="true"
            style="height: 40px; text-align: center; margin-right: 0px"
            class="button-blue"
          >
            <ion-icon
              [name]="isActive ? 'eye' : 'eye-off'"
              style="font-size: 20px"
            ></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item class="register_item">
          <a class="required"></a>
          <ion-input
            label="Confirmar Contraseña"
            label-placement="floating"
            class="floating"
            [type]="isActive2 ? 'text' : 'password'"
            minlength="8"
            maxlength="15"
            name="passConfirm"
            [(ngModel)]="register_data.passConfirm"
            required
          ></ion-input>
          <ion-button
            type="button"
            (click)="isActive2 = !isActive2"
            isActive2="true"
            style="height: 40px; text-align: center; margin-right: 0px"
            class="button-blue"
          >
            <ion-icon
              [name]="isActive2 ? 'eye' : 'eye-off'"
              style="font-size: 20px"
            ></ion-icon>
          </ion-button>
        </ion-item>

        <span class="span_alert"
          >* Deben tener un largo entre 8 y 15 caracteres.</span
        >
        <br />
        <span
          *ngIf="register_data.password != register_data.passConfirm"
          class="span_alert"
          >"Las contraseñas no coinciden."</span
        >
      </ion-list>

      <ion-list *ngIf="register_data.c_type == 2" class="represent_list">
        <h5 padding>Representante Legal</h5>
        <ion-item class="register_item">
          <ion-select
            label="Tipo de Cédula"
            label-placement="floating"
            class="floating"
            [(ngModel)]="register_data.represent_c_type"
            [ngModelOptions]="{ standalone: true }"
            interface="popover"
          >
            <ion-select-option
              *ngFor="let ct of card_types"
              (ionSelect)="updateCardType(ct.value)"
              value="{{ ct.value }}"
              >{{ ct.name }}</ion-select-option
            >
          </ion-select>
        </ion-item>

        <ion-item class="register_item">
          <a class="required"></a>

          <ion-input
            label="{{ card_types[register_data.represent_c_type].format }}"
            label-placement="floating"
            class="floating"
            type="text"
            mask="*-****-****"
            maxlength="11"
            *ngIf="card_types[register_data.represent_c_type].value === 0"
            name="represent_id_card"
            [(ngModel)]="register_data.represent_id_card"
            required
          ></ion-input>

          <ion-input
            label="{{ card_types[register_data.represent_c_type].format }}"
            label-placement="floating"
            class="floating"
            type="text"
            mask="********************"
            minlength="1"
            maxlength="20"
            *ngIf="card_types[register_data.represent_c_type].value === 1"
            name="represent_id_card"
            [(ngModel)]="register_data.represent_id_card"
            required
          ></ion-input>

          <ion-input
            label="{{ card_types[register_data.represent_c_type].format }}"
            label-placement="floating"
            class="floating"
            type="text"
            mask="*-***-******"
            maxlength="12"
            *ngIf="card_types[register_data.represent_c_type].value === 2"
            name="represent_id_card"
            [(ngModel)]="register_data.represent_id_card"
            required
          ></ion-input>

          <ion-icon
            name="ios-close"
            style="z-index: 1"
            item-right
            (click)="deleteIdCard()"
          ></ion-icon>
        </ion-item>

        <ion-item class="register_item">
          <!-- Aquí podrías usar un ícono o un asterisco para indicar que es obligatorio -->
          <ion-label 
            [attr.aria-label]="register_data.represent_c_type === 2 ? 'Organización' : 'Nombre'" 
            position="floating"
            >{{ register_data.represent_c_type === 2 ? 'Organización' : 'Nombre' }}</ion-label>
          
          <ion-input
            class="floating"
            type="text"
            name="represent_user_name"
            [(ngModel)]="register_data.represent_user_name"
            required
            aria-required="true"
          ></ion-input>
        </ion-item>
        
      </ion-list>
    </form>
  </div>
</ion-content>

<ion-fab vertical="bottom" horizontal="end">
  <ion-fab-button
    color="primary"
    class="submit-btn"
    type="submit"
    [disabled]="
      !registerForm.form.valid ||
      register_data.password != register_data.passConfirm"
      size="small"
    (click)="registerNextStep()"
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-fab-button>
</ion-fab>
