<app-nav-bar [title]="'Recepción de traslado'">
  <ion-buttons slot="end">
    <ion-button (click)="refreshAssetRequest()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <div class="box" style="display: flex; flex-direction: column; height: 100%;">
    <div class="content" style="flex: 1;">
      <app-asset-request-list
        *ngIf="requestsList.length"
        [requestList]="requestsList"
        [requestType]="2"
        (requestListCheckedEmit)="setRequestListChecked($event)"
      ></app-asset-request-list>
    </div>
    <div
      style="display: flex; flex-direction: row; justify-content: flex-end; margin-top: 2%; margin-bottom: 2%;"
      class="flex-container nowrap infContent"
    >
      <ion-button
        color="danger"
        (click)="presentAssetReqActionPopover(4)"
        style="margin: 10px"
        [disabled]="!requestListChecked.length"
      >
        Rechazar
      </ion-button>
      <ion-button
        [disabled]="!(requestListChecked.length && !disabledAcceptBtn())"
        style="margin: 10px"
        (click)="presentAssetReqActionPopover(1)"
      >
        Aceptar
      </ion-button>
    </div>
  </div>
</ion-content>

