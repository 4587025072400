// Components

// Plugins-Native
import { Component } from '@angular/core';

// Providers
import { webServiceProvider } from './../../provider/webServiceProvider';

// BusinessCore
import {
  AlertController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NavigationData } from 'src/app/GeneralUtilis/Navigation/Navigation-data';
import { NavigationRoutes } from 'src/app/GeneralUtilis/Navigation/Navigation-routes';
import { NavigationService } from 'src/app/GeneralUtilis/Navigation/NavigationElement';
import { InsertPasswordComponent } from '../insert-password/insert-password.component';
import { RegisterData } from './../../businessCore/RegisterData';

@Component({
  selector: 'app-validate-client',
  templateUrl: './validate-client.component.html',
  styleUrls: ['./validate-client.component.scss'],
})
export class ValidateClientComponent {
  id_card: string; //id card of the customer
  step: number; //The value of the first step, always 1
  stepCondition: boolean; //Set to true if you don't need condition in every step
  stepDefaultCondition: boolean; //Save the default condition for every step

  validationInProgress: boolean = false; // Control if the validation event is in progress

  card_types: any; // array with the card types (juridica, fisico, extranjero)
  c_type: number; // card_type: juridica, fisico, extranjero
  user_name: string; // string with the name of the customer

  register_data: RegisterData; // instance of RegisterData, contains all the data of the customer

  /**
   * Class constructor with these injections:
   * @param nav NavController is the base class for navigation controller components like Nav and Tab.
   * You use navigation controllers to navigate to pages in your app.
   * @param _apiProvider Web service provider located in './../../provider/webServiceProvider'
   * @param popoverCtrl dialog that appears on top of the current page.
   * It can be used for anything, but generally it is used for overflow actions
   * that don't fit in the navigation bar.
   * @param alertCtrl dialog that presents users with information or collects
   * information from the user using inputs.
   */
  constructor(
    private navService: NavigationService,
    private _apiProvider: webServiceProvider,
    private popoverCtrl: PopoverController,
    public alertCtrl: AlertController,
    public toasCtrl: ToastController
  ) {
    this.step = 1;
    this.stepCondition = false;
    this.stepDefaultCondition = this.stepCondition;

    this.register_data = new RegisterData();
    this.card_types = this.register_data.get_card_types();

    this.c_type = -1;
  }

  /**
   * Method used to present the popover to complete a customer register.
   * This popover appears if the condition 2 is fulfilled
   */
  async presentPopover() {
    const popover = await this.popoverCtrl.create({
      cssClass: 'custom-popover',
      component: InsertPasswordComponent,
    });
    await popover.present();
  
    const { data } = await popover.onDidDismiss(); // Esperar a que se cierre el popover
    console.log('Respuesta Modal', data);
    if (data != null) {
      const pass = data as string;
      this._apiProvider.registerPassword(this.id_card, pass).subscribe(async (data) => {
        let alert;
        if (data != null) {
          alert = await this.alertCtrl.create({
            header: 'Cuenta Registrada con éxito!',
            subHeader: 'Por favor ingrese su cuenta en la sección de Login.',
            buttons: ['OK'],
          });
        } else {
          alert = await this.alertCtrl.create({
            header: 'No se pudo registrar la cuenta!',
            subHeader: 'Por favor inténtelo de nuevo.',
            buttons: ['OK'],
          });
        }
    
        await alert.present();
      });
    }
  }
  

  /**
   * Method that validate the register of the customer with the ID and card type
   * 1- If the customer doesn't exist, the data is saved into the register_data and then
   * the app is redirect to RegisterMethodComponent
   * 2- If the id is validated and response.state = 1, the user is client of MERCASA
   * but isn't register like client of HomexExpress
   * 3- If the id is validated and response.state = 2, the user account already exist and the
   * register process is cancelled
   */
  public validate() {
    this.validationInProgress = true;
    this._apiProvider
      .getCustomerName(this.id_card, this.c_type)
      .subscribe(async (data) => {
        if (data) {
          let response = data as { name: string; state: number };
          this.user_name = String(response.name);

          // El usuario no se encuentra registrado
          if (response.state == 0) {
            // Se llenan los datos ingresados por el usuario en la estructura register_data
            this.register_data = new RegisterData();
            this.register_data.c_type = this.c_type;
            this.register_data.c_name = this.card_types[this.c_type].name;
            this.register_data.id_card = this.id_card;
            this.register_data.user_name = this.user_name;
            // Se redirige al siguiente paso del registro (RegisterMethodComponent)
            // con los datos ingresados por el usuario y registrados en register_data
            this.redirectTo(NavigationRoutes.RegisterMethodComponent, {
              registerData: this.register_data,
            });
          }
          // El usuario es cliente pero no registrado en la aplicacion
          else if (response.state == 1) {
            this.presentPopover();
          }
          // El usuario ya se encuentra registrado y se redirige a Login
          else if (response.state == 2) {
            const alert = await this.alertCtrl.create({
              header: 'Cuenta Registrada!',
              subHeader:
                this.user_name +
                ' se encuentra registrad@, ingrese su cuenta en la sección de Login.',
              buttons: ['OK'],
            });

            alert.present();
          }
          // El usuario ya cuenta con un registro en proceso
          else if (response.state == 3) {
            let toast = await this.toasCtrl.create({
              message:
                'El usuario ya cuenta con un registro en proceso, por favor espere.',
              duration: 5000,
              position: 'bottom',
              buttons: ['Ok'],
              cssClass: 'toastClass',
            });

            toast.present();
            this.id_card = '';
          }
          // El usuario es de credito y por ende no se le permite el registro
          else if (response.state == 5) {
            let toast = await this.toasCtrl.create({
              message:
                'Esta aplicación por seguridad se encuentra bloqueada para clientes de crédito! En versiones posteriores se habilitará su uso.',
              duration: 5000,
              position: 'bottom',
              buttons: ['Ok'],
              cssClass: 'toastClass',
            });
            toast.present();

            this.id_card = '';
          }
        } else {
          let toast = await this.toasCtrl.create({
            message:
              'No se pudo consultar la información del cliente, Inténtelo más tarde.',
            duration: 4000,
            position: 'bottom',
            buttons: ['Ok'],
          });
          toast.present();
        }

        this.validationInProgress = false;
      });
  }

  /**
   * Method used to redirect the app to a specific component with some data
   * @param component object with the component
   * @param data string with the structure(JSON)
   * of the data that will be send to the component
   */
  private redirectTo(component: NavigationRoutes, data: NavigationData) {
    //this.nav.push(component, data);
    this.navService.navigateTo(component, data);
  }

  /**
   * Method used to update or select an specific card type
   * @param ct number with the id of the selected card type
   */
  public updateCardType(ct) {
    this.c_type = ct;
    this.id_card = '';
  }

  /**
   * Method used to erase the idcard input
   */
  public deleteIdCard() {
    this.id_card = '';
  }
}
