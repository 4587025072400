<app-nav-bar [title]="'Solicitud de traslado'">
  <ion-buttons slot="end">
    <ion-button (click)="refreshAssets()">
      <ion-icon name="refresh"></ion-icon>
    </ion-button>
  </ion-buttons>
</app-nav-bar>

<ion-content>
  <ng-container *ngIf="secondScreen">
    <div class="box">
      <ion-searchbar
        type="text"
        placeholder="Buscar"
        (ionInput)="searchAsset($event)"
      ></ion-searchbar>

      <ion-grid>
        <ion-row>
          <ion-col size="6">
            <ion-button expand="block" (click)="presentAssetFilterPopover()">
              Filtros
              <ion-icon name="options"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button expand="block" (click)="presentAssetSortAlert()">
              Ordenar
              <ion-icon name="reorder-three-outline"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <p class="text-warning">Total de activos: {{ assetsListByWorker.length }}</p>

      <ion-item>
        <!-- Checkbox para seleccionar todo -->
        <ion-checkbox
          (ionChange)="selectAllAssets($event)"
          labelPlacement="start" slot="start">
        </ion-checkbox>
        <ion-label>
          Seleccionar todo
        </ion-label>
      </ion-item>
      

      <ion-list>
        <ion-item *ngFor="let asset of assetsListToSearch; let i = index" 
                  [ngClass]="{ 'affected-asset': asset.affected }"
                  [disabled]="asset.pending || asset.affected"
                  lines="full">
          <ion-checkbox
            slot="start"
            [(ngModel)]="asset.selected"
            [disabled]="asset.pending || asset.affected"
            (ionChange)="getCheckedvalue()">
          </ion-checkbox>
      
          <ion-label>
            <p *ngIf="asset.barcode" class="barcode-text">  
              Código de barras: {{ asset.getBarcode() }} 
            </p>
            <h3>
              <div [ngClass]="{ required: asset.approval }"> 
                {{ asset.getAssetId() }}: {{ asset.getAssetName() }} 
              </div>
              <div *ngIf="asset.getSubAssets().length" class="markedNumber" (click)="toggleAccordion(asset)">
                &nbsp;+{{ asset.getSubAssets().length }}&nbsp;
              </div>
            </h3>
            <p> {{ asset.getDataAreaId() }}: {{ asset.getCompanyName() }} </p>
            <p>{{ asset.getAssetGroupName() }}</p>
            <p *ngIf="asset.pending" class="pending-text"> 
              Transferido a: {{ asset.getNewResponsible() }} 
            </p>
            <div *ngIf="asset.affected" class="ui-select-toggle">
              Nota: Este subactivo posee un dueño diferente al del activo principal
            </div>
            <div *ngIf="asset.expanded">
              <ion-item *ngFor="let subAsset of asset.getSubAssets(); let i = index">
                <h3>{{ i + 1 }}) {{ subAsset.getAssetName() }}</h3>
              </ion-item>
            </div>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button [disabled]="!assetsListChecked.length" (click)="changeScreen(3)" size="small">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>

  <ng-container *ngIf="thirdScreen">
    <div class="box">
      <!-- Selector para nuevo responsable -->
       <div>
        <ion-item (click)="selectNewResponsible()">
        <ion-label class="label-name required">Nuevo responsable</ion-label>
        <ion-label class="label-selected">{{ newAssetResponsible?.getName() }}</ion-label>
        <ion-icon name="caret-down-outline" slot="end"></ion-icon>
      </ion-item>
  


       </div>
     
      <!-- Selector para nueva ubicación -->
      <ion-item [disabled]="!assetLocations?.length" (click)="selectNewLocation()">
        <ion-label class="label-name required">Aposento</ion-label>
        <ion-label class="label-selected">{{ newAssetLocation?.getName() || 'Seleccionar aposento' }}</ion-label>
        <ion-icon name="caret-down-outline" slot="end"></ion-icon>
      </ion-item>
  
      <!-- Checkbox para mostrar más opciones de ubicación -->
      <ion-item>
        <ion-checkbox [(ngModel)]="checkNewLocation" (ionChange)="switchLocations()"></ion-checkbox>
        <ion-label>Mostrar más aposentos</ion-label>
      </ion-item>
  
      <!-- Lista de activos seleccionados -->
      <ion-card>
        <ion-card-content>
          <ion-list>
            <ion-item *ngFor="let item of assetsListChecked">
              - {{ item.getAssetId() }}: {{ item.getAssetName() }}
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
  
      <!-- Botón para volver a la pantalla anterior -->
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button (click)="changeScreen(2)" size="small">
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
  
      <!-- Botón para realizar la transferencia, habilitado sólo si hay responsable y aposento -->
      <ion-fab *ngIf="newAssetResponsible?.getName() && newAssetLocation?.getName()" vertical="bottom" horizontal="end" slot="fixed">
        <ion-button (click)="transferAssets()">Transferir</ion-button>
      </ion-fab>
    </div>
  </ng-container>
  
</ion-content>
