import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-insert-password',
  templateUrl: './insert-password.component.html',
  styleUrls: ['./insert-password.component.scss'],
})
export class InsertPasswordComponent {
  // atributo con la nueva contrasenha que esta registrando el cliente
  password: string;

  // confirmacion de la nueva contrasenha para evitar errores de registro
  passConfirm: string;

  /**
   * Class constructor with this injection:
   * @param viewController Access various features and information about the current view.
   */
  constructor(private viewController: PopoverController) {}

  /**
   * Metodo utilizado para dar por finalizada la insercion de la contrasenha
   * al momento de que un usuario ya se encuentra registrado pero
   * debe registrar una clave de acceso para utilizar el sistema .
   */
  addPassword() {
    this.viewController.dismiss(this.password);
  }

  /**
   * Metodo utilizado para validar el formulario de insercion de datos
   * En este caso se valida que la contrasenha coincida con los requisitos minimos
   * y que se confirme la contrasenha correctamente
   * @returns boolean, indicando si el formulario es correcto o no
   */
  validForm() {
    
    if (
      this.password &&
      this.passConfirm &&
      this.password == this.passConfirm &&
      7 < this.password.length &&
      this.password.length < 16 &&
      7 < this.passConfirm.length &&
      this.passConfirm.length < 16
    ) {
      return true;
    }
    return false;
  }
}
