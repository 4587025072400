<ion-item-sliding (swipe)="swipeEvent($event)">
  <ion-item-options side="end">
    <ion-item-option color="danger" (click)="goToOmit()">
      <ion-icon name="close"></ion-icon>
      <span style="font-size: 8px">Omitir</span>
    </ion-item-option>
  </ion-item-options>

  <ion-item (click)="showAnswerInfo()" [ngClass]="getStyle()">
    <ion-label>
      <h2>{{ position }} - {{ Task.getName() }}</h2>
      <p>{{ Task.getDescription() }}</p>
      <p style="color: red" *ngIf="Task.getAttached() && !Task.savedImage()">
        Imagen Obligatoria
      </p>
      <p style="color: green" *ngIf="Task.savedImage()">Imagen guardada</p>
    </ion-label>

    <ion-checkbox
      *ngIf="Task.getAnswerTaskList().length === 0"
      item-end
      (ionChange)="changeSelected()"
      [(ngModel)]="Task.selected"
    ></ion-checkbox>
  </ion-item>

  <ion-item-options
    side="start"
    *ngIf="Task.getAttached() || observationLeft()"
  >
    <ion-item-option
      color="primary"
      (click)="getObservationLeft()"
      *ngIf="observationLeft()"
    >
      <ion-icon name="document"></ion-icon>
      <span style="font-size: 8px">Observación</span>
    </ion-item-option>
    <ion-item-option
      color="primary"
      (click)="openFileChooser()"
      *ngIf="Task.getAttached()"
    >
      <ion-icon name="attach"></ion-icon>
      <span style="font-size: 8px">Adjunto</span>
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>

<!-- Agregar un input de tipo file -->
<input type="file" accept="image/*" (change)="onFileSelected($event)" style="display: none;" #fileInput>
